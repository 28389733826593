<template>
    <div>
        <a-spin v-if="isSpinning" :spinning="isSpinning" size="large" style="margin-top:30px;">
            <div class="spin-content">
                <a-row>
                    <a-col class="app-setup">
                        <!-- <div v-html="loadingContent"></div> -->
                    </a-col>
                </a-row>
            </div>
        </a-spin>
        
        <a-row v-if="!isSpinning">
            <a-col class="app-activation">

                <h2>{{ t('Activate your kit') }}</h2>

                <p style="margin-bottom:30px;">{{ t('Enter the Activation Code found on the Kit ID Card inside your kit.') }}</p>

                <error v-if="error" :error="error" />

                <a-form
                    id="form-normal-activation"
                    :form="form"
                    class="activation-form"
                    layout="vertical"
                    @submit.prevent="handleActivationCodeSubmit"
                >

                    <a-form-item
                      :label="t('Activation Code')"
                    >
                    <a-input          
                        v-decorator="[
                            'activation_code',
                            { rules: [{ required: true, message: t('Activation code cannot be blank.') }] },
                        ]"
                        class="activation-code"
                        placeholder="XXXX-XXXX-XXXX-XXXX"
                        size="large"
                        :max-length="19"
                    >
                    </a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button :disabled="formSubmitting" :loading="formSubmitting" type="primary" html-type="submit" class="activation-form-button" size="large" :style="website ? 'background-color:' + website.rgb.button + '; border-color:' + website.rgb.button  : ''">
                            {{ formSubmitting ? t('Submitting...') : t('Continue') }}
                        </a-button>
                    </a-form-item>
                    <div v-if="isLoggedIn === false" style="text-align:center;margin-bottom:20px;">
                        <span v-html="alreadyHaveAccountText"></span>
                    </div>
                    <div style="text-align:center;margin-bottom:30px;">
                        <a @click="showModalHelp">{{ t('Can\'t find your Kit ID?') }}</a>
                    </div>
                    <div style="text-align:center;margin-bottom:30px;" v-if="website.has_account_creation_link && !isLoggedIn">
                        {{ t('Not all kits have an activation code. Don\'t have an activation code?') }} <router-link :to="{ path: '/' + regionId + '/' + websiteId + '/login/order' }">{{ t('Create an account using your Order ID #.') }}</router-link>
                    </div>

                    <div v-if="isLoggedIn === true" style="text-align:center;margin-bottom:30px;">
                        <router-link :to="{ path: '/' + regionId + '/' + websiteId + '/account' }">{{ t('Return to your account') }} <a-icon type="right" style="font-size:0.6em;margin-left:3px;" /></router-link>
                    </div>
                </a-form>

            </a-col>
        </a-row>

        <a-modal
            title="Find your Kit ID"
            v-model="helpVisible"
            :visible="helpVisible"
            :cancel-button-props="{ props: { disabled: true } }"
            :footer=null
            @ok="helpHandleOk"
            >
            
            <p style="margin:0 0 15px;">{{ t('Your Kit ID is found on the inner flap of your kit. It is also found on the Kit ID Card inside your kit.') }}</p>
            <div style="text-align:center;">
                <img
                    :src="homeActivationImage.url"
                    alt=""
                    :style="(homeActivationImage.width ? 'width:' + homeActivationImage.width + 'px;' : '') + 'height:' + (homeActivationImage.height ? homeActivationImage.height + 'px;' : (!homeActivationImage.width  ? '100%' : 'auto'))"
                />
            </div>
            
        </a-modal>
        
    </div>
</template>


<script>
    import axiosUregInstance from '../apiUreg'
    import Error from './Error.vue'

    // eslint-disable-next-line no-unused-vars
    import t from '../utils'

    export default {
        name: 'ActivateComponent',
        components: {
            Error
        },
        computed: {
            isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
            user : function(){ return this.$store.getters.getUser},
            token : function(){ return this.$store.getters.getToken},
            website : function(){ return this.$store.getters.getWebsite},
            alreadyHaveAccountText() {
                return t('If you already have an account, <a href="%s">login to continue</a>', '/' + this.regionId + '/' + this.websiteId + '/login');
            }
        },
        data() {
            return {
                regionId: this.$route.params.region_id ? this.$route.params.region_id : process.env.VUE_APP_DEFAULT_REGION_ID,
                websiteId: this.$route.params.website_id ? this.$route.params.website_id : process.env.VUE_APP_DEFAULT_WEBSITE_ID,
                formSubmitting: false,
                activation_code: '',
                error: '',
                homeActivationImage: {
                    url: '',
                    height: '',
                    width: ''
                },
                helpVisible: false,
                isSpinning: true,
                t: t
            }
        },
        mounted: function() {
            this.checkStatusRedirect();

            // this.checkMaintenance();

            this.$nextTick(function () {
                const activationCodeElement = document.querySelector('.activation-code');
                if (activationCodeElement) {
                    // Add the event listener if the element exists
                    activationCodeElement.addEventListener('input', function () {
                        var foo = this.value.split("-").join("");
                        if (foo.length > 0) {
                            foo = foo.match(new RegExp('.{1,4}', 'g')).join("-");
                        }
                        this.value = foo;
                    });
                }
            })
        },
        created: function() {
            this.getHomeImageRequest();
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'normal_activation' });
        },
        methods: {

            async processActivationResponse(resp) {
                this.error = '';
                this.$store.dispatch('setEnv', {});
                this.$store.dispatch('setActivation', resp.data.activation);
                this.getResetRequest();

                if (resp.data.redirect !== false) {

                    this.regionId = resp.data.redirect.region_id;
                    this.websiteId = resp.data.redirect.website_id;

                }

                console.log('---------');
                console.log(resp.data.activation.input.product_types.relationship);
                console.log(resp.data.activation.input.activation_mode);
                console.log(this.isLoggedIn);

                if (this.isLoggedIn) {

                    if (resp.data.activation.input.activation_mode == 'key' && (!this.$route.query.via || (this.$route.query.via && this.$route.query.via != 'console'))  ) {
                        // kick logged-in user.
                        this.$store.dispatch('setUser', {});
                        this.$store.dispatch('setToken', null);
                        this.$store.dispatch('setRefreshToken', null);
                        window.location.href = '/' + this.regionId + '/' + this.websiteId + '/login?via=secure-status&for=' + resp.data.activation.input.activation_code_code;
                    }
                    else {
                        if (this.$store.getters.getActivation.input.is_activation_questionnaire_required == true) {
                            // this.$router.push(process.env.BASE_URL + 'contact?via=activate');
                            this.$router.push('/' + this.regionId + '/' + this.websiteId + '/test-confirm?via=activate');
                        }
                        else {
                            this.$router.push('/' + this.regionId + '/' + this.websiteId + '/who?via=activate');
                        }
                    }
                    
                }
                else {

                    var key = this.$route.query.key ? this.$route.query.key : null;

                    if (resp.data.activation.input.activation_mode == 'key' && resp.data.activation.input.product_types.relationship == false) {
                        this.$router.push('/' + this.regionId + '/' + this.websiteId + '/signup?via=activate' + (key !== null ? '&key=' + key : ''));
                    }
                    else {
                        // Clement: if collection_category_id is not 3 (private)
                        if (resp.data.activation.input.collection_category_id != 3) {
                            this.$router.push('/' + this.regionId + '/' + this.websiteId + '/signup?via=activate' + (key !== null ? '&key=' + key : ''));
                        }
                        else if (this.$store.getters.getActivation.input.is_received == true) {
                            this.$router.push('/' + this.regionId + '/' + this.websiteId + '/signup?via=activate' + (key !== null ? '&key=' + key : ''));
                        }
                        else {
                            if (this.$store.getters.getActivation.input.is_activation_questionnaire_required == true) {
                                this.$router.push('/' + this.regionId + '/' + this.websiteId + '/test-confirm?via=activate' + (key !== null ? '&key=' + key : ''));
                            }
                            else {
                                this.$router.push('/' + this.regionId + '/' + this.websiteId + '/signup?via=activate' + (key !== null ? '&key=' + key : ''));
                            }
                        }
                    }

                }
                // this.$message.success('Activcation code validated. Please proceed.', 2.5);
            },

            async checkStatusRedirect() {
                // console.log('key')
                // console.log(this.$route.query.key ? 'true' : 'false');
                console.log(this.$route.query.activation_code);
                try {
                    if (this.$route.query.key) {
                        const response = await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/activation-codes/retrieve', {
                            key: this.$route.query.key
                        });
                        // console.log('response:');
                        // console.log(response);  
                        this.processActivationResponse(response);
                    }
                    else if (this.$route.query.activation_code) {
                        console.log(this.$route.query.activation_code);
                        this.generateActivationResponse(this.$route.query.activation_code);
                    }
                    this.isSpinning = false;
                } catch(e) {
                    this.formSubmitting = false;
                    this.error = e.response.data.error;
                }
            },

            async handleActivationCodeSubmit() {
                try {

                    this.formSubmitting = true;

                    const firstRes = await this.form.validateFields();

                    if (firstRes) {
                        this.generateActivationResponse(firstRes.activation_code);
                    }
                    
                } catch(e) {
                    this.formSubmitting = false;
                    this.error = e.response.data.error;
                }
                
            },

            async generateActivationResponse(code) {
                try {
                    if (code) {
                        const response = await axiosUregInstance.post(this.regionId + '/' + this.websiteId + '/activation-codes/verify', {
                            activation_code: code,
                            region_id: this.regionId
                        });
                        this.processActivationResponse(response);
                    }
                } catch(e) {
                    this.formSubmitting = false;
                    this.error = e.response.data.error;
                }
            },

            async getHomeImageRequest() { 
                let res = await axiosUregInstance.get(this.regionId + '/' + this.websiteId + '/websites');
                this.homeActivationImage = res.data.website.images.activation_form ? res.data.website.images.activation_form : require('@/assets/thumb-activate.png');
            },

            async getResetRequest() {
                this.$store.dispatch('setPhone', {});
                this.$store.dispatch('setContact', {});
                this.$store.dispatch('setMedical', {});
                this.$store.dispatch('setClientOrder', {});
                this.$store.dispatch('setPatients', {});
                this.$store.dispatch('setEnv', {});
                return true;
            },

            showModalHelp: async function() {
                this.helpVisible = true;
            },

            helpHandleOk() {
                this.helpVisible = false;
            },

            async checkMaintenance() {
                const excludedIps = ['216.71.196.211', '207.6.11.226', '207.81.81.56'];
                fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(y => {
                if (!excludedIps.includes(y.ip)) {
                    this.$router.push('/' + this.$route.params.region_id + '/' + this.$route.params.website_id + '/maintenance');
                }
                else {
                    return
                }
                });
            }
        }        
    }
    
</script>

<style>
.anticon-loading {
    margin-right:10px !important;
}
.app-activation {
  max-width: 490px;
  margin: 0 auto;
  padding: 30px 20px 0;
}
.activation-code {
  font-size:1.4em !important;
}
.ant-form-vertical .ant-form-item-label {
  padding:0 0 5px;
}
#form-normal-activation .activation-form {
  max-width: 300px;
}
#form-normal-activation .activation-form-button {
  margin:10px 0 5px;
  width: 100%;
}
</style>